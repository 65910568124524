import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const fetchReviewers = createAsyncThunk(
  "reviewers/fetchReviewers",
  async (conferenceId) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/conference/${conferenceId}/reviewers`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );
    console.log(response.data.data);
    return response.data;
  }
);

export const addReviewer = createAsyncThunk(
  "reviewers/addReviewer",
  async ({ conferenceId, email }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/conference/reviewer/add`,
      {
        conferenceId,
        reviewerEmail: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      }
    );

    return response.data;
  }
);

const reviewersSlice = createSlice({
  name: "reviewerSlice",
  initialState: {
    reviewers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchReviewers.fulfilled, (state, action) => {
        state.loading = false;
        state.reviewers = action.payload.data;
      })
      .addCase(fetchReviewers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        notification.error({ message: "Failed to fetch reviewers." });
      })
      .addCase(addReviewer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addReviewer.fulfilled, (state, action) => {
        state.loading = false;
        notification.success({ message: "Reviewer added successfully." });
      })
      .addCase(addReviewer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        notification.error({
          message: "Failed to add reviewer.",
          description: action.payload.error,
        });
      });
  },
});

export default reviewersSlice.reducer;
