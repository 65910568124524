import { Button, Form, Input, Spin, Tabs, notification } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkJWTValidity } from "../../assets/constants";

const LoginTab = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleLoginSubmit = (values) => {
    setLoading(true);
    const { loginEmail, loginPassword } = values;
    const data = JSON.stringify({ email: loginEmail, password: loginPassword });

    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/login`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        if (response.data.success) {
          const token = response.data.data.token;
          localStorage.setItem("authToken", token);

          return axios.get(`${process.env.REACT_APP_API_URL}/user/me`, {
            headers: { Authorization: `Bearer ${token}` },
          });
        } else {
          throw new Error("Invalid credentials");
        }
      })
      .then((userResponse) => {
        setLoading(false);
        localStorage.setItem(
          "userData",
          JSON.stringify(userResponse.data.data)
        );
        const { profileComplete, userType } = userResponse.data.data;
        if (profileComplete) {
          navigate(
            userType === "SUPER_ADMIN" || userType === "ADMIN"
              ? "/Adashboard"
              : "/dashboard"
          );
        } else {
          navigate("/registerUser");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Login error:", error);
        notification.error({
          message: "Login Failed",
          description: "Invalid email or password",
        });
      });
  };
  return (
    <Form
      form={form}
      onFinish={handleLoginSubmit}
      className="loginFormContainer"
    >
      <Form.Item
        name="loginEmail"
        rules={[{ required: true, message: "Please input your email!" }]}
      >
        <Input placeholder="Email" type="email" />
      </Form.Item>

      <Form.Item
        name="loginPassword"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          placeholder="Password"
          visibilityToggle={{
            visible: showPassword,
            onVisibleChange: setShowPassword,
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button disabled={loading} type="primary" htmlType="submit" block>
          {loading ? <Spin tip="Loading..." /> : "Login"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginTab;
