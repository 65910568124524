import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Login from "./pages/loginSignup/Login";
import RegistrationUserForm from "./pages/loginSignup/RegistrationUserForm";
import Dashboard from "./pages/Dashboard/Dashboard";
import ConferenceDetails from "./pages/conferenceDetail/ConferenceDetail";
import CreateNewSubmission from "./pages/conferenceDetail/CreateNewSubmission";
import Navbar from "./components/Navbar";
import CreateConference from "./pages/Dashboard/CreateConference";
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import AdminUsersList from "./pages/AdminUsers/AdminUsersList";
import ConferenceSettings from "./pages/ConferenceSetting/ConferenceSettings";
import ConferenceQuestionair from "./pages/ConferenceSetting/ConferenceQuestionair";
import EmailTemplateSetting from "./pages/emailTemplates/EmailTemplateSetting";
import PaperDetails from "./pages/conferenceDetail/PaperDetails";
import EnterReview from "./pages/conferenceDetail/EnterReview";
import EditReviewByChair from "./pages/conferenceDetail/EditReviewByChair";
import ForgotPassword from "./pages/loginSignup/ForgotPassword";

const ProtectedRoute = ({ element, ...rest }) => {
  const token = localStorage.getItem("authToken");
  const location = useLocation();

  if (!token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element;
};

function App() {
  const token = localStorage.getItem("authToken");
  console.log(token);
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/fp" element={<ForgotPassword />} />
          <Route
            path="/registerUser"
            element={<ProtectedRoute element={<RegistrationUserForm />} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectedRoute element={<Dashboard />} />}
          />
          <Route
            path="/Adashboard"
            element={<ProtectedRoute element={<AdminDashboard />} />}
          />
          <Route
            path="/conferenceDetail/:confId"
            element={<ProtectedRoute element={<ConferenceDetails />} />}
          />
          <Route
            path="/conferenceQuestions/:confId"
            element={<ProtectedRoute element={<ConferenceQuestionair />} />}
          />
          <Route
            path="/conferenceSetting/:confId"
            element={<ProtectedRoute element={<ConferenceSettings />} />}
          />
          <Route
            path="/createSubmission"
            element={<ProtectedRoute element={<CreateNewSubmission />} />}
          />
          <Route
            path="/paperDetails"
            element={<ProtectedRoute element={<PaperDetails />} />}
          />
          <Route
            path="/createConference"
            element={<ProtectedRoute element={<CreateConference />} />}
          />
          <Route
            path="/usersList"
            element={<ProtectedRoute element={<AdminUsersList />} />}
          />
          <Route
            path="/emailTemplates/:confId"
            element={<ProtectedRoute element={<EmailTemplateSetting />} />}
          />
          <Route
            path="/review/create/:paperId"
            element={<ProtectedRoute element={<EnterReview />} />}
          />
          <Route
            path="/review/editByChair/:confId"
            element={<ProtectedRoute element={<EditReviewByChair />} />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
