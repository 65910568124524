import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Table,
  Select,
  notification,
  Spin,
} from "antd";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  fetchConferenceDetails,
  updateConferenceData,
} from "../../store/slices/conferenceSlice";
import { fetchTracksAndConfDetails } from "../../store/slices/tracksListSlice";
import { updateSetting } from "../../store/slices/updateSettingSlice";
import { render } from "@testing-library/react";
import AddDocSubmissionModal from "./AddDocSubmissionModal";
import { eventTypeList } from "../../assets/enumConstants";

const { TextArea } = Input;
const { Option } = Select;

const ConferenceSettings = () => {
  const dispatch = useDispatch();
  const { confId } = useParams();
  const conferenceData = useSelector((state) => state.conferenceDetail);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [tracks, setTracks] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [editableRow, setEditableRow] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [isAddDocModalVisible, setIsAddDocModalVisible] = useState(false);

  const handleEditClick = (record) => {
    setEditableRow(record.id);
    setEditedData(record);
  };

  const handleSaveClick = (id) => {
    const deadlineDate = moment(editedData?.deadline).toDate();

    const dataToSend = {
      conferenceId: confId,
      id: id,
      deadline: deadlineDate.toISOString(),
      status: editedData.status,
    };

    dispatch(updateSetting(dataToSend))
      .then(() => {
        dispatch(fetchConferenceDetails(confId));
      })
      .catch((error) => {
        console.error("Failed to update setting:", error);
      });

    setEditableRow(null);
    setEditedData({});
  };

  const handleChange = (e, key) => {
    setEditedData((prevData) => ({
      ...prevData,
      [key]: e.target.value,
    }));
  };

  useEffect(() => {
    if (!conferenceData.data) {
      dispatch(fetchConferenceDetails(confId));
    }
    dispatch(fetchTracksAndConfDetails(confId)).then((response) => {
      if (response.payload) {
        setTracks(response.payload.tracks);
      }
    });
  }, [confId]);

  useEffect(() => {
    if (conferenceData.data?.conferenceSettings) {
      const {
        paperSubmission,
        editSubmission,
        cameraReadySubmission,
        reviewSubmission,
      } = conferenceData.data.conferenceSettings;
      const settingsArray = [
        { name: "Paper Submission", ...paperSubmission },
        { name: "Edit Submission", ...editSubmission },
        { name: "Camera Ready Submission", ...cameraReadySubmission },
        { name: "Review Submission", ...reviewSubmission },
      ].filter((item) => item.deadline && item.status);
      setSettingsData(settingsArray);
    }
  }, [conferenceData.data]);

  const onFinish = (values) => {
    const conferenceDetails = {
      conferenceId: confId,
      ...values,
      startDate: values.startDate.toISOString(),
      endDate: values.endDate.toISOString(),
      tracks: tracks.map((track) => ({
        name: track.name,
        description: track.description,
      })),
    };

    dispatch(updateConferenceData(conferenceDetails)).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(fetchConferenceDetails(confId));
        setIsEditing(false);
      } else {
        notification.error({
          message: "Failed to update conference. Please try again.",
        });
      }
    });
  };

  const columns = [
    {
      title: "Track Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const accptedDocColumns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Document Type",
      dataIndex: "docType",
      key: "docType",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <Button danger onClick={() => alert("Coming Soon!")}>
          Delete
        </Button>
      ),
    },
  ];

  const settingsColumns = [
    {
      title: "Setting Name",
      dataIndex: "name",
      render: (text, record) => text,
    },
    {
      title: "Deadline",
      dataIndex: "deadline",
      render: (text, record) =>
        editableRow === record.id ? (
          <Input
            type="date"
            value={moment(editedData.deadline).format("YYYY-MM-DD")}
            onChange={(e) => handleChange(e, "deadline")}
          />
        ) : (
          moment(text).format("DD-MM-YYYY")
        ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) =>
        editableRow === record.id ? (
          <Select
            value={editedData.status}
            onChange={(value) => handleChange({ target: { value } }, "status")}
          >
            <Option value="ENABLED">Enabled</Option>
            <Option value="DISABLED">Disabled</Option>
          </Select>
        ) : (
          text
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        editableRow === record.id ? (
          <Button onClick={() => handleSaveClick(record.id)}>Save</Button>
        ) : (
          <Button onClick={() => handleEditClick(record)}>Edit</Button>
        ),
    },
  ];

  if (conferenceData.loading) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div className="conferenceSettings_heading">
        <h2>Conference Details</h2>
        {isEditing ? null : (
          <Button type="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>

      {isEditing ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            title: conferenceData?.data?.title || "",
            handle: conferenceData?.data?.handle || "",
            websiteUrl: conferenceData?.data?.websiteUrl || "",
            description: conferenceData?.data?.description || "",
            affiliationName: conferenceData?.data?.affiliationName || "",
            location: conferenceData?.data?.location || "",
            startDate: conferenceData?.data?.conferenceSettings?.startDate
              ? moment(conferenceData.data.conferenceSettings.startDate)
              : null,
            endDate: conferenceData?.data?.conferenceSettings?.endDate
              ? moment(conferenceData.data.conferenceSettings.endDate)
              : null,
            eventType: conferenceData?.data?.eventType || "",
          }}
        >
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>

          <Form.Item label="Handle" name="handle">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Website URL" name="websiteUrl">
            <Input />
          </Form.Item>

          <Form.Item label="Description" name="description">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Affiliation Name" name="affiliationName">
            <Input />
          </Form.Item>

          <Form.Item label="Location" name="location">
            <Input />
          </Form.Item>

          <Form.Item label="Start Date" name="startDate">
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>

          <Form.Item label="End Date" name="endDate">
            <DatePicker format="DD-MM-YYYY" />
          </Form.Item>

          <Form.Item label="Event Type" name="eventType">
            <Select>
              {eventTypeList.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
            <Button type="default" onClick={() => setIsEditing(false)}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <div>
          <Form layout="vertical">
            <Form.Item label="Title">
              <h4>{conferenceData?.data?.title}</h4>
            </Form.Item>

            <Form.Item label="Handle">
              <h4>{conferenceData?.data?.handle}</h4>
            </Form.Item>

            <Form.Item label="Website URL">
              <h4>{conferenceData?.data?.websiteUrl}</h4>
            </Form.Item>

            <Form.Item label="Description">
              <h4>{conferenceData?.data?.description}</h4>
            </Form.Item>

            <Form.Item label="Affiliation Name">
              <h4>{conferenceData?.data?.affiliationName}</h4>
            </Form.Item>

            <Form.Item label="Location">
              <h4>{conferenceData?.data?.location}</h4>
            </Form.Item>

            <Form.Item label="Start Date">
              <h4>
                {moment(
                  conferenceData?.data?.conferenceSettings?.startDate
                ).format("DD-MM-YYYY")}
              </h4>
            </Form.Item>

            <Form.Item label="End Date">
              <h4>
                {moment(
                  conferenceData?.data?.conferenceSettings?.endDate
                ).format("DD-MM-YYYY")}
              </h4>
            </Form.Item>

            <Form.Item label="Event Type">
              <h4>{conferenceData?.data?.eventType}</h4>
            </Form.Item>
          </Form>
        </div>
      )}

      <h3 style={{ margin: "2rem 0" }}>Tracks</h3>

      <Table
        columns={columns}
        dataSource={tracks}
        rowKey="id"
        pagination={false}
      />
      <h3 style={{ margin: "2rem 0" }}>Deadlines</h3>
      <Table
        columns={settingsColumns}
        dataSource={settingsData}
        rowKey="id"
        pagination={false}
      />
      <div className="conferenceSettings_heading">
        <h3 style={{ margin: "2rem 0" }}>Accepted Paper Documents</h3>
        <Button type="primary" onClick={() => setIsAddDocModalVisible(true)}>
          Add Document
        </Button>
      </div>
      <Table
        columns={accptedDocColumns}
        dataSource={conferenceData?.data?.cameraReadySubmissionSettings}
        rowKey="id"
        pagination={false}
      />

      <AddDocSubmissionModal
        setIsAddDocModalVisible={setIsAddDocModalVisible}
        isAddDocModalVisible={isAddDocModalVisible}
        confId={confId}
      />
    </div>
  );
};

export default ConferenceSettings;
