// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import conferenceDetail from "./slices/conferenceSlice";
import allConferenceList from "./slices/allConferenceSlice";
import userRoleForConference from "./slices/userRoleForConference";
import userConfereceBasedOnRole from "./slices/userConfereceBasedOnRole";
import submitPaper from "./slices/createSubmissionSlice";
import tracksListAndConfDetails from "./slices/tracksListSlice";
import myUniqueConferences from "./slices/myUniqueConferences";
import createConference from "./slices/createConferenceSlice";
import allConferenceAdminList from "./slices/allConferenceAdminSlice";
import changeConferenceStatus from "./slices/changeConfApprovedSlice";
import usersList from "./slices/adminUsersListSlice";
import reviewersSlice from "./slices/adminAddreviewerSlice";
import settingUpdate from "./slices/updateSettingSlice";
import questionslist from "./slices/fetchQuestionsSlice";
import assignReviewerSlice from "./slices/assignReviewerSlice";
import addquestionsSlice from "./slices/addConfferenceQuestionSlice";
import deletePaperSlice from "./slices/deletePaperSlice";
import userListActionSlice from "./slices/userListActionSlice";
import updatePaperStatus from "./slices/updatePaperSlice";
import emailTemplateCreate from "./slices/emailTemplateAddSlice";
import paperInfoState from "./slices/paperInfoSlice";
import downloadConferenceSubmissionsSlice from "./slices/donwloadAllSubmissionSlice";
import conferenceEmailTemplates from "./slices/emailTemplateFetchSlice";
import conferenceQuestionsState from "./slices/fetchConferenceQuestionsSlice";
import addReviewSlice from "./slices/addReviewSlice";
import fetchConferenceEmailTemplatesSlice from "./slices/fetchEmailTemplatedslice";
import sendEmail from "./slices/sendEmailSlice";
import deleteQuestionSlice from "./slices/deleteQuestionSlice";
import fetchReviewsSlice from "./slices/fetchReviewsSlice";
import editReviewByChairSlice from "./slices/EditReviewByChairSlice";
import submissionSettingsSlice from "./slices/addDocumentSubSlice";
import addSubmissionDocSlice from "./slices/addSubmissionDocSlice";
import sendReviewReminderSlice from "./slices/sendReviewReminderSlice";

const store = configureStore({
  reducer: {
    conferenceDetail,
    allConferenceList,
    userRoleForConference,
    userConfereceBasedOnRole,
    submitPaper,

    tracksListAndConfDetails,
    myUniqueConferences,
    createConference,
    allConferenceAdminList,
    changeConferenceStatus,

    usersList,
    reviewersSlice,
    settingUpdate,
    questionslist,
    deleteQuestionSlice,
    assignReviewerSlice,

    addquestionsSlice,
    deletePaperSlice,
    userListActionSlice,
    updatePaperStatus,

    emailTemplateCreate,
    paperInfoState,
    downloadConferenceSubmissionsSlice,
    conferenceEmailTemplates,

    conferenceQuestionsState,
    addReviewSlice,
    fetchConferenceEmailTemplatesSlice,
    sendEmail,

    fetchReviewsSlice,
    editReviewByChairSlice,

    submissionSettingsSlice,
    addSubmissionDocSlice,

    sendReviewReminderSlice,
  },
});

export default store;
