import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axios from "axios";

export const sendEmail = createAsyncThunk(
  "conference/sendEmail",
  async (data, thunkAPI) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL}/conference/author/email/send`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const emailSlice = createSlice({
  name: "email",
  initialState: {
    loading: false,
    error: null,
    response: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
        notification.info({
          message: "Sending email...",
        });
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        state.error = null;
        notification.success({
          message: "Email sent successfully!",
        });
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.response = null;
        notification.error({
          message: "Failed to send email",
          description: action.payload.error,
        });
      });
  },
});

export default emailSlice.reducer;
