import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  Button,
  Upload,
  Table,
  notification,
  Spin,
  Tooltip,
} from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, useroles } from "react-router-dom";
import { fetchpaperSubmissionDetails } from "../../store/slices/paperInfoSlice";
import {
  capitalizeFirstLetterStatus,
  convertIsoToDate,
} from "../../assets/constants";
import AssignReviewerModal from "./AssignReviewerModal";
import ChangeStatusModal from "./ChangeStatus";
import SendSingleEmailModal from "../emailTemplates/SendSingleEmailModal";
import ViewReviewModal from "./ViewReviewModal";
import EditSubmissionModal from "./EditSubmissionModal";
import { fetchConferenceDetails } from "../../store/slices/conferenceSlice";
import UploadSubmissionDocModal from "./UploadSubmissionDocModal";
import { sendReviewReminderEmail } from "../../store/slices/sendReviewReminderSlice";

const PaperDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).id
    : "";
  const location = useLocation();
  const conferenceData = useSelector((state) => state.conferenceDetail);
  const sendReviewReminderSlice = useSelector(
    (state) => state.sendReviewReminderSlice
  );
  const { id, role } = location.state || {};
  const [isAssignReviewerModalVisible, setIsAssignReviewerModalVisible] =
    useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);

  const [isSendSingleEmailModalVisible, setIsSendSingleEmailModalVisible] =
    useState(false);
  const [isEditSubmissionModalVisible, setIsEditSubmissionModalVisible] =
    useState(false);
  const [reviewId, setreviewId] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isViewReviewModalVisible, setIsViewReviewModalVisible] =
    useState(false);
  const [editingSubmissionData, setEditingSubmissionData] = useState(null);
  const [isUploadSubmissionDocModal, setIsUploadSubmissionDocModal] =
    useState(false);
  const [uploadSubmissionData, setUploadSubmissionData] = useState(null);

  const [filteredReviews, setFilteredReviews] = useState(null);
  const currentDate = new Date();

  const [reload, setReload] = useState(0);
  const submissionData2 = useSelector((state) => state.paperInfoState);
  const submissionData = submissionData2?.paperSubmissionDetails;
  console.log("submissionData", submissionData);
  console.log("conferenceData", conferenceData);

  useEffect(() => {
    if (!id || !role) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchpaperSubmissionDetails(id));
  }, [id, reload]);

  useEffect(() => {
    if (!conferenceData.data && submissionData) {
      dispatch(fetchConferenceDetails(submissionData?.conferenceId));
    }
  }, [submissionData2]);

  useEffect(() => {
    if (role === "r" && submissionData && userId) {
      const matchingReview = submissionData?.reviews.find(
        (review) => review.reviewer.id === userId
      );

      if (matchingReview) {
        setreviewId(matchingReview);
      } else {
        console.log("No matching review found for the specified userId.");
      }
    }

    if (role === "a" && submissionData && userId) {
      const filteredReviews = submissionData?.reviews.filter(
        (review) => review.status === "SUBMITTED"
      );

      setFilteredReviews(filteredReviews);
    }
  }, [userId, submissionData]);

  const handleEditSubmissionModal = (record) => {
    setIsEditSubmissionModalVisible(true);
    setEditingSubmissionData(record);
  };

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, row, index) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => <p>{text ? text : "Not Registered"}</p>,
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const reviewersColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, row, index) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "reviewer",
      key: "name",
      render: (reviewer) => (
        <p>{reviewer.name ? reviewer.name : "Not Registered"}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "reviewer",
      key: "email",
      render: (reviewer) => <p>{reviewer.email}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <p>{status}</p>,
    },
    {
      title: "Verdict",
      dataIndex: "verdict",
      key: "verdict",
      render: (verdict) => <p>{verdict ?? "-"}</p>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (text, record) => (
        console.log("record", record),
        record?.status === "PENDING" ? (
          <Button
            type="primary"
            disabled={sendReviewReminderSlice.loading}
            onClick={() => {
              dispatch(sendReviewReminderEmail(record.id));
            }}
          >
            {sendReviewReminderSlice.loading ? "Sending..." : "Send Reminder"}
          </Button>
        ) : (
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button type="primary">
              <Link
                to={`/review/editByChair/${submissionData?.conferenceId}`}
                state={{ record }}
              >
                Edit
              </Link>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setSelectedReview(record);
                setIsViewReviewModalVisible(true);
              }}
            >
              View Reviews
            </Button>
          </div>
        )
      ),
    },
  ];

  const authorReviewColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, row, index) => <p>Reviewer {index + 1}</p>,
    },
    {
      title: "Verdict",
      dataIndex: "verdict",
      key: "verdict",
      render: (verdict) => <p>{verdict ?? "-"}</p>,
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "actions",
      render: (text, record) => (
        console.log("record", record),
        (
          <Button
            type="primary"
            onClick={() => {
              setSelectedReview(record);
              setIsViewReviewModalVisible(true);
            }}
          >
            View Reviews
          </Button>
        )
      ),
    },
  ];
  const accptedDocColumns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Document Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Document Type",
      dataIndex: "docType",
      key: "docType",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        const matchedSubmission = submissionData.submissions.find(
          (submission) => submission.submissionSetting.id === record.id
        );

        return conferenceData?.data?.conferenceSettings?.cameraReadySubmission
          ?.status === "ENABLED" &&
          currentDate <
            new Date(
              conferenceData?.data?.conferenceSettings?.cameraReadySubmission?.deadline
            ) ? (
          matchedSubmission ? (
            <div style={{ display: "flex", gap: "1rem" }}>
              <Button
                type="primary"
                onClick={() => {
                  setUploadSubmissionData(record);
                  setIsUploadSubmissionDocModal(true);
                }}
              >
                Replace
              </Button>
              <Button
                type="default"
                onClick={() => window.open(matchedSubmission.url, "_blank")}
              >
                Download
              </Button>
            </div>
          ) : (
            <Button
              danger
              onClick={() => {
                setUploadSubmissionData(record);
                setIsUploadSubmissionDocModal(true);
              }}
            >
              Upload
            </Button>
          )
        ) : (
          <Button disabled>Deadline Passed</Button>
        );
      },
    },
  ];

  if (submissionData2.loading) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  // const tempDate = "2021-08-30T00:00:00.000Z";

  const editSubmissionStatus =
    conferenceData?.data?.conferenceSettings?.editSubmission?.status;
  const editSubmissionDeadline = new Date(
    conferenceData?.data?.conferenceSettings?.editSubmission?.deadline
  );
  const iseditSubmissionDeadlinePassed = currentDate > editSubmissionDeadline;

  const reviewSubmissionStatus =
    conferenceData?.data?.conferenceSettings?.reviewSubmission?.status;
  const isPaperReviewingDeadlinePassed =
    currentDate >
    new Date(
      conferenceData?.data?.conferenceSettings?.reviewSubmission?.deadline
    );

  const isAnyReviewSubmitted = filteredReviews?.some(
    (review) => review.status === "SUBMITTED"
  );

  const isEditButtonVisible =
    role === "a" &&
    ((editSubmissionStatus === "ENABLED" && !iseditSubmissionDeadlinePassed) ||
      editSubmissionStatus !== "ENABLED");

  const isEditButtonDisabled = isAnyReviewSubmitted || !isEditButtonVisible;

  console.log("submissionData", isAnyReviewSubmitted);

  return (
    <div className="pageContainer ">
      <div className="paperDetails">
        <div className="glassmorph paperDetails">
          <div className="paperDetails_heading">
            <h1>Title: {submissionData?.paperName}</h1>

            {role === "c" && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <Button
                  type="primary"
                  onClick={() => setIsStatusModalVisible(true)}
                >
                  Change Status
                </Button>
                <Button
                  type="primary"
                  onClick={() => setIsSendSingleEmailModalVisible(true)}
                >
                  Send Email
                </Button>
              </div>
            )}
          </div>

          <div className="paperDetails_row1">
            <p>
              <b>Conference Name:</b> {submissionData?.conference?.title}
            </p>

            <p>
              <b>Track:</b> {submissionData?.track?.name}
            </p>
            <p>
              <b>Updated Date:</b>{" "}
              {submissionData?.updatedAt
                ? convertIsoToDate(submissionData?.updatedAt)
                : "Not Updated"}
            </p>
            <p>
              <b>Status:</b>{" "}
              {capitalizeFirstLetterStatus(submissionData?.status)}
            </p>
          </div>

          <div>
            <h2>Abstract</h2>
            <p>{submissionData?.abstract}</p>
          </div>
        </div>

        <div className="paperDetails_row2 glassmorph">
          <h2>Authors</h2>
          <Table
            style={{ marginTop: "1rem" }}
            columns={columns}
            dataSource={submissionData?.authors}
            pagination={false}
            size="small"
            rowKey={(record) => record.id}
          />
        </div>

        {role === "c" && (
          <div className="paperDetails_row3 glassmorph">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h2>Reviewers</h2>

              <Button
                onClick={() => setIsAssignReviewerModalVisible(true)}
                type="primary"
              >
                Add Reviewer
              </Button>
            </div>
            {submissionData?.reviews?.length > 0 && (
              <Table
                style={{ marginTop: "1rem" }}
                columns={reviewersColumns}
                dataSource={submissionData?.reviews}
                pagination={false}
                size="small"
                rowKey={(record) => record.id}
              />
            )}
          </div>
        )}

        {role === "a" &&
        (submissionData?.status === "ACCEPTED" ||
          submissionData?.status === "REJECTED" ||
          submissionData?.status === "REGISTERED") ? (
          <div className="paperDetails_row3 glassmorph">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <h2>Reviews</h2>
            </div>
            {submissionData?.reviews?.length > 0 && (
              <Table
                columns={authorReviewColumns}
                dataSource={filteredReviews}
                pagination={false}
                size="small"
                rowKey={(record) => record.id}
              />
            )}
          </div>
        ) : null}

        <div
          className="glassmorph"
          style={{ display: "flex", gap: "1rem", alignItems: "center" }}
        >
          <h2>Download File</h2>
          <Button
            href={submissionData?.fileUrl}
            style={{ width: "max-content" }}
            target="_blank"
          >
            <CloudDownloadOutlined />
            {submissionData?.paperName}
          </Button>
        </div>

        {role === "r" ? (
          reviewId?.status === "PENDING" ? (
            !isPaperReviewingDeadlinePassed ||
            reviewSubmissionStatus !== "ENABLED" ? (
              <div style={{ display: "flex" }}>
                <Button type="primary">
                  <Link
                    to={`/review/create/${submissionData?.conferenceId}`}
                    state={{ reviewId }}
                  >
                    Submit Review
                  </Link>
                </Button>
              </div>
            ) : (
              <div style={{ marginTop: "2rem" }}>
                <h2>Your Review</h2>
                <p>You missed the deadline for submitting your review.</p>
              </div>
            )
          ) : (
            <div className="glassmorph">
              <h2>Your Review</h2>
              <div className="newReview_row1_detail">
                <h4>Review Status:</h4> <span>{reviewId?.status}</span>
              </div>
              <div className="newReview_row1_detail">
                <h4>Review Verdict</h4> <span>{reviewId?.verdict}</span>
              </div>
              <div className="newReview_row1_detail">
                <h4>Review Comment</h4> <span>{reviewId?.comment}</span>
              </div>
            </div>
          )
        ) : null}

        {role === "a" ? (
          <div children="glassmorph" style={{ display: "flex", gap: "2rem" }}>
            <h2>Actions</h2>
            <Button
              type="primary"
              onClick={() => handleEditSubmissionModal(submissionData)}
              disabled={isEditButtonDisabled}
            >
              {isEditButtonDisabled ? (
                <Tooltip title="You missed the deadline for editing your submission or a review has been submitted.">
                  Edit Submission
                </Tooltip>
              ) : (
                "Edit Submission"
              )}
            </Button>
          </div>
        ) : null}

        {role === "a" && submissionData?.status === "REGISTERED" ? (
          <div className="glassmorph" style={{ gap: "2rem" }}>
            <h2>Upload Final Documents</h2>
            <Table
              columns={accptedDocColumns}
              dataSource={conferenceData?.data?.cameraReadySubmissionSettings}
              rowKey="id"
              pagination={false}
            />
          </div>
        ) : null}
      </div>

      <AssignReviewerModal
        visible={isAssignReviewerModalVisible}
        onClose={() => setIsAssignReviewerModalVisible(false)}
        record={id}
        conferenceId={submissionData?.conferenceId}
        setReload={setReload}
      />

      <ChangeStatusModal
        visible={isStatusModalVisible}
        onClose={() => setIsStatusModalVisible(false)}
        record={submissionData}
        setReload={setReload}
      />

      {/* <AddReviewModal
        isAddReviewModalOpen={isAddReviewModalOpen}
        onClose={() => setIsAddReviewModalOpen(false)}
        confId={submissionData?.conferenceId}
        reviewId={reviewId}
      /> */}

      <SendSingleEmailModal
        isSendSingleEmailModalVisible={isSendSingleEmailModalVisible}
        handleSendSingleEmailModalClose={() =>
          setIsSendSingleEmailModalVisible(false)
        }
        conferenceId={submissionData?.conferenceId}
        paperid={id}
      />

      <ViewReviewModal
        visible={isViewReviewModalVisible}
        onClose={() => setIsViewReviewModalVisible(false)}
        record={selectedReview}
        role={role}
      />

      <EditSubmissionModal
        visible={isEditSubmissionModalVisible}
        onClose={() => setIsEditSubmissionModalVisible(false)}
        record={editingSubmissionData}
        setReload={setReload}
      />

      <UploadSubmissionDocModal
        visible={isUploadSubmissionDocModal}
        onClose={() => setIsUploadSubmissionDocModal(false)}
        record={uploadSubmissionData}
        paperid={id}
      />
    </div>
  );
};

export default PaperDetails;
