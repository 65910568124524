import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConferenceDetails } from "../../store/slices/conferenceSlice";
import { Link, useNavigate, useParams } from "react-router-dom";
import { userRoleForConference } from "../../store/slices/userRoleForConference";
import { Select, Spin, Table, Button, Modal, Menu, Dropdown } from "antd";
import { userConfereceBasedOnRole } from "../../store/slices/userConfereceBasedOnRole";
import { DownOutlined, MoreOutlined } from "@ant-design/icons";
import {
  capitalizeFirstLetter,
  capitalizeFirstLetterStatus,
} from "../../assets/constants";
import ReviewsModal from "./ReviewsModal";
import { fetchMyUniqueConferences } from "../../store/slices/myUniqueConferences";
import AddReviewersModal from "./AddReviewersModal";
import { deleteSubmission } from "../../store/slices/deletePaperSlice";
import AssignReviewerModal from "./AssignReviewerModal";
import ChangeStatusModal from "./ChangeStatus";
import { downloadConferenceSubmissions } from "../../store/slices/donwloadAllSubmissionSlice";
import SendBulkEmailModal from "./SendBulkEmailModal";
import EditSubmissionModal from "./EditSubmissionModal";

const { Option } = Select;
const { confirm } = Modal;

const disabledStatuses = ["REVIEWED", "REJECTED", "WITHDRAWN", "DEST_REJECT"];

const ConferenceDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confId } = useParams();
  const userId = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData")).id
    : "";

  // Local States
  const [userRolesList, setUserRolesList] = useState([]);
  const [selectedRole, setselectedRole] = useState(null);
  const [userConfereceBasedOnRoleList, setUserConfereceBasedOnRoleList] =
    useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isAddReviewersModalVisible, setIsAddReviewersModalVisible] =
    useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isEditSubmissionModalVisible, setIsEditSubmissionModalVisible] =
    useState(false);
  const [editingSubmissionData, setEditingSubmissionData] = useState(null);

  const [editingStatus, setEditingStatus] = useState(null);
  const [isSendBulkEmailModalVisible, setIsSendBulkEmailModalVisible] =
    useState(false);
  const [reload, setReload] = useState(0);

  const conferenceData = useSelector((state) => state.conferenceDetail);
  const userRoles = useSelector((state) => state.userRoleForConference);
  const userConfereceBasedOnRoleListState = useSelector(
    (state) => state.userConfereceBasedOnRole
  );
  const myConferencesState = useSelector((state) => state.myUniqueConferences);

  useEffect(() => {
    if (confId) {
      dispatch(fetchConferenceDetails(confId));
      dispatch(userRoleForConference(confId));
    }

    if (!myConferencesState?.data) {
      dispatch(fetchMyUniqueConferences());
    }
  }, [confId]);

  useEffect(() => {
    if (userConfereceBasedOnRoleListState?.status === "ok") {
      setUserConfereceBasedOnRoleList(userConfereceBasedOnRoleListState?.data);
    }
  }, [userConfereceBasedOnRoleListState]);

  useEffect(() => {
    if (selectedRole) {
      let payload = {
        conferenceId: confId,
        role: selectedRole,
      };
      dispatch(userConfereceBasedOnRole(payload));
    }
  }, [selectedRole, reload]);

  useEffect(() => {
    if (userRoles.status === "ok") {
      const roles = userRoles?.data?.data || [];

      const defaultRoles = [
        { id: "author", role: "Author" },
        { id: "reviewer", role: "Reviewer" },
        { id: "chair", role: "Chair" },
      ];

      const filteredRoles = defaultRoles.filter((defaultRole) => {
        // Ensure "Author" is always in the list
        if (defaultRole.role.toUpperCase() === "AUTHOR") {
          return true;
        }
        return roles.some(
          (role) => role.role.toUpperCase() === defaultRole.role.toUpperCase()
        );
      });

      const capitalizedRoles = filteredRoles.map((defaultRole) => {
        const foundRole = roles.find(
          (role) => role.role.toUpperCase() === defaultRole.role.toUpperCase()
        );
        return foundRole
          ? { ...foundRole, role: capitalizeFirstLetter(foundRole.role) }
          : defaultRole;
      });

      setUserRolesList(capitalizedRoles);

      // Determine the default selected role based on the presence of "CHAIR" and "Reviewer"
      if (
        capitalizedRoles.some((role) => role.role.toUpperCase() === "CHAIR")
      ) {
        setselectedRole("CHAIR");
      } else if (
        capitalizedRoles.some((role) => role.role.toUpperCase() === "REVIEWER")
      ) {
        setselectedRole("REVIEWER");
      } else {
        setselectedRole("AUTHOR");
      }
    }
  }, [userRoles]);

  const handleModalOk = () => {
    setIsModalVisible(false);
    setSelectedReview(null);
  };

  const handleEditSubmissionModal = (record) => {
    setIsEditSubmissionModalVisible(true);
    setEditingSubmissionData(record);
  };

  const chairMenu = (
    <Menu>
      <Menu.Item key="1">
        <Link to={`/conferenceQuestions/${confId}`} state={{ confId: confId }}>
          Review Questions
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          type="link"
          onClick={() => setIsAddReviewersModalVisible(true)}
          style={{ padding: 0 }}
        >
          Add Reviewers
        </Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link onClick={() => setIsSendBulkEmailModalVisible(true)}>
          Send Bulk Email
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={`/emailTemplates/${confId}`}>Email Template Settings</Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link to={`/conferenceSetting/${confId}`}>Settings</Link>
      </Menu.Item>
      <Menu.Item key="5">
        <Link onClick={() => dispatch(downloadConferenceSubmissions(confId))}>
          Download All Submissions
        </Link>
      </Menu.Item>
    </Menu>
  );

  const paperOptionsMenu = (record) => {
    const isActionDisabled = disabledStatuses.includes(record.status);

    console.log("record", record);
    const matchingReview = record?.reviews.find(
      (review) => review?.reviewer?.id === userId
    );

    console.log("matchingReview", matchingReview);

    return (
      <Menu>
        {/* {(selectedRole === "AUTHOR" || selectedRole === "CHAIR") &&
          record.reviews &&
          record.reviews.length > 0 && (
            <Menu.Item onClick={() => showReviewModal(record.reviews)}>
              View Reviews
            </Menu.Item>
          )} */}
        {/* {selectedRole === "REVIEWER" && (
          <Menu.Item>
            <Link
              to={`/review/create/${record?.id}`}
              state={{ reviewId: matchingReview }}
            >
              Enter Review
            </Link>
          </Menu.Item>
        )} */}
        {selectedRole === "AUTHOR" && (
          <>
            <Menu.Item onClick={() => handleEditSubmissionModal(record)}>
              Edit Submission
            </Menu.Item>

            <Menu.Item
              onClick={() => handleDeleteSubmission(record.id)}
              danger
              disabled={isActionDisabled}
            >
              Delete Submission
            </Menu.Item>
          </>
        )}
      </Menu>
    );
  };

  const handleDeleteSubmission = (submissionId) => {
    confirm({
      title: "Are you sure you want to delete this submission?",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        dispatch(deleteSubmission(submissionId)).then(() => {
          let payload = {
            conferenceId: confId,
            role: selectedRole,
          };
          dispatch(userConfereceBasedOnRole(payload));
        });
      },
      onCancel() {},
    });
  };

  const expandedRowRender = (record) => (
    <p>
      <strong>Abstract:</strong> {record.abstract || "N/A"}
    </p>
  );

  const handleChangeStatus = (record) => {
    setEditingStatus(record);
    setIsStatusModalVisible(true);
  };

  const columns = [
    {
      title: "Paper Id",
      dataIndex: "externalRefId",
      key: "externalRefId",
      render: (text, record) => (
        <Link
          className="conferenceLink"
          to={`/paperDetails`}
          state={{ id: record.id, role: "a" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Paper Name",
      dataIndex: "paperName",
      key: "paperName",
      // render: (text, record) => ({ text }),
    },
    {
      title: "Track",
      dataIndex: ["track", "name"],
      key: "track",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => capitalizeFirstLetterStatus(text),
    },
    {
      title: "Download Paper",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text, record) => (
        <a
          target="_blank"
          className="conferenceLink"
          href={record.fileUrl}
          download
        >
          Download
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Dropdown overlay={paperOptionsMenu(record)} trigger={["click"]}>
          <Button icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const reviewersColumns = [
    {
      title: "Paper Id",
      dataIndex: "externalRefId",
      key: "externalRefId",
      render: (text, record) => (
        <Link
          className="conferenceLink"
          to={`/paperDetails`}
          state={{ id: record.id, role: "r" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Paper Name",
      dataIndex: "paperName",
      key: "paperName",
      // render: (text, record) => (
      //   <Link
      //     className="conferenceLink"
      //     to={`/paperDetails`}
      //     state={{ id: record.id, role: "r" }}
      //   >
      //     {text}
      //   </Link>
      // ),
    },
    {
      title: "Track",
      dataIndex: ["track", "name"],
      key: "track",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const matchingReview = record?.reviews.find(
          (review) => review?.reviewerId === userId
        );
        console.log("matchingReview", matchingReview);
        return (
          <div>
            {matchingReview
              ? capitalizeFirstLetterStatus(matchingReview.status)
              : "No Review"}
          </div>
        );
      },
    },
    {
      title: "Download Paper",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text, record) => (
        <a
          target="_blank"
          className="conferenceLink"
          href={record.fileUrl}
          download
        >
          Download
        </a>
      ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   render: (text, record) => (
    //     <Dropdown overlay={paperOptionsMenu(record)} trigger={["click"]}>
    //       <Button icon={<MoreOutlined />} />
    //     </Dropdown>
    //   ),
    // },
  ];

  const columnsForChair = [
    {
      title: "Paper Id",
      dataIndex: "externalRefId",
      key: "externalRefId",
      render: (text, record) => (
        <Link
          className="conferenceLink"
          to={`/paperDetails`}
          state={{ id: record.id, role: "c" }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Paper Name",
      dataIndex: "paperName",
      key: "paperName",
      // render: (text, record) => (
      //   <Link
      //     className="conferenceLink"
      //     to={`/paperDetails`}
      //     state={{ id: record.id, role: "c" }}
      //   >
      //     {text}
      //   </Link>
      // ),
    },
    {
      title: "Track",
      dataIndex: ["track", "name"],
      key: "track",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => capitalizeFirstLetterStatus(text),
    },
    {
      title: "Reviewers Assigned",
      dataIndex: "reviews",
      key: "reviewers",
      render: (text) => text?.length || 0,
    },
    {
      title: "Download Paper",
      dataIndex: "fileUrl",
      key: "fileUrl",
      render: (text, record) => (
        <a
          target="_blank"
          className="conferenceLink"
          href={record.fileUrl}
          download
        >
          Download
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Button type="default" onClick={() => handleChangeStatus(record)}>
            Change Status
          </Button>
        </div>
      ),
    },
  ];

  if (
    conferenceData.status === "loading" ||
    userRoles.status === "loading" ||
    userConfereceBasedOnRoleListState.status === "loading"
  ) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  const tempDate = "2021-09-30T00:00:00.000Z";
  const paperSubmissionStatus =
    conferenceData?.data?.conferenceSettings?.paperSubmission?.status;
  const paperSubmissionDeadline = new Date(
    conferenceData?.data?.conferenceSettings?.paperSubmission?.deadline
  );
  const currentDate = new Date();
  const isDeadlinePassed = currentDate > paperSubmissionDeadline;

  return (
    <div className="pageContainer">
      <div className="glassmorph">
        <div className="conferenceDetail_row1">
          <h1>{conferenceData?.data?.handle}</h1>
          <div className="conferenceDetail_row1_right">
            {selectedRole === "AUTHOR" &&
              conferenceData?.data?.approvalStatus === "APPROVED" &&
              (paperSubmissionStatus !== "ENABLED" || !isDeadlinePassed) && (
                <Link to="/createSubmission" state={{ confId: confId }}>
                  <Button type="primary">Create New Submission</Button>
                </Link>
              )}

            {selectedRole === "CHAIR" && (
              <Dropdown overlay={chairMenu} trigger={["click"]}>
                <Button type="primary">
                  Chair options <DownOutlined />
                </Button>
              </Dropdown>
            )}

            <Select
              style={{ width: 100 }}
              placeholder="Select a role"
              value={capitalizeFirstLetter(selectedRole)}
              onChange={(value) => setselectedRole(value.toUpperCase())}
            >
              {userRolesList?.map((item) => (
                <Option key={item.id} value={item.role.toUpperCase()}>
                  {capitalizeFirstLetter(item.role)}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      {userConfereceBasedOnRoleList && selectedRole === "AUTHOR" && (
        <div style={{ marginTop: "2rem" }}>
          <Table
            columns={columns}
            dataSource={userConfereceBasedOnRoleList}
            rowKey="id"
          />
        </div>
      )}

      {userConfereceBasedOnRoleList && selectedRole === "CHAIR" && (
        <div style={{ marginTop: "2rem" }}>
          <Table
            columns={columnsForChair}
            dataSource={userConfereceBasedOnRoleList}
            rowKey="id"
            expandable={{ expandedRowRender }}
          />
        </div>
      )}

      {userConfereceBasedOnRoleList && selectedRole === "REVIEWER" && (
        <div style={{ marginTop: "2rem" }}>
          <Table
            columns={reviewersColumns}
            dataSource={userConfereceBasedOnRoleList}
            rowKey="id"
          />
        </div>
      )}

      <ReviewsModal
        isModalVisible={isModalVisible}
        handleModalOk={handleModalOk}
        selectedReview={selectedReview}
      />

      <AddReviewersModal
        visible={isAddReviewersModalVisible}
        onClose={() => setIsAddReviewersModalVisible(false)}
        conferenceId={confId}
        setReload={setReload}
      />

      <ChangeStatusModal
        visible={isStatusModalVisible}
        onClose={() => setIsStatusModalVisible(false)}
        record={editingStatus}
        setReload={setReload}
      />

      <SendBulkEmailModal
        conferenceId={confId}
        isSendBulkEmailModalVisible={isSendBulkEmailModalVisible}
        handleSendBulkEmailModalClose={() =>
          setIsSendBulkEmailModalVisible(false)
        }
        userConfereceBasedOnRoleList={userConfereceBasedOnRoleList}
      />

      <EditSubmissionModal
        visible={isEditSubmissionModalVisible}
        onClose={() => setIsEditSubmissionModalVisible(false)}
        record={editingSubmissionData}
        setReload={setReload}
      />
    </div>
  );
};

export default ConferenceDetails;
