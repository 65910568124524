import { Button, Form, Modal, notification, Select, Table, Input } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchConferenceEmailTemplates } from "../../store/slices/fetchEmailTemplatedslice";
import { sendEmail } from "../../store/slices/sendEmailSlice";

const SendBulkEmailModal = ({
  conferenceId,
  handleSendBulkEmailModalClose,
  isSendBulkEmailModalVisible,
  userConfereceBasedOnRoleList,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedPapers, setSelectedPapers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredPapers, setFilteredPapers] = useState(
    userConfereceBasedOnRoleList
  );

  const sendEmailSlice = useSelector((state) => state.sendEmail);

  const templates = useSelector(
    (state) => state.fetchConferenceEmailTemplatesSlice
  );

  useEffect(() => {
    if (conferenceId) dispatch(fetchConferenceEmailTemplates(conferenceId));
  }, [conferenceId]);

  useEffect(() => {
    if (userConfereceBasedOnRoleList) {
      setFilteredPapers(
        userConfereceBasedOnRoleList.filter(
          (paper) =>
            paper.paperName.toLowerCase().includes(searchText.toLowerCase()) ||
            paper.externalRefId.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  }, [searchText, userConfereceBasedOnRoleList]);

  const handlePaperSelect = (selectedRowKeys) => {
    setSelectedPapers(selectedRowKeys);
  };

  const handleSubmit = () => {
    if (!selectedTemplate || !selectedPapers.length) {
      notification.error({
        message: "Please select a template and atleast one paper!",
      });
      return;
    }
    const data = {
      paperIds: selectedPapers,
      templateId: selectedTemplate,
      conferenceId: conferenceId,
    };

    console.log("data", data);
    dispatch(sendEmail(data)).then(() => {
      handleSendBulkEmailModalClose();
      form.resetFields();
      setSelectedPapers([]);
    });
  };

  const columns = [
    {
      title: "Paper Id",
      dataIndex: "externalRefId",
      key: "externalRefId",
    },
    {
      title: "Paper Name",
      dataIndex: "paperName",
      key: "paperName",
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedPapers,
    onChange: handlePaperSelect,
  };

  return (
    <Modal
      title="Send Email to Authors"
      open={isSendBulkEmailModalVisible}
      onCancel={handleSendBulkEmailModalClose}
      footer={[
        <Button key="cancel" onClick={handleSendBulkEmailModalClose}>
          Cancel
        </Button>,
        <Button
          key="send"
          type="primary"
          disabled={sendEmailSlice.loading}
          onClick={handleSubmit}
        >
          {sendEmailSlice.loading ? "Sending..." : "Send"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Select Template"
          name="template"
          rules={[{ required: true, message: "Please select a template!" }]}
        >
          <Select
            placeholder="Select a template"
            onChange={(value) => setSelectedTemplate(value)}
          >
            {templates?.templates?.data?.map((template) => (
              <Select.Option key={template.id} value={template.id}>
                {template.templateName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Search Paper">
          <Input
            placeholder="Search by paper name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </Form.Item>

        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={filteredPapers}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ y: 140 }}
        />
      </Form>
    </Modal>
  );
};

export default SendBulkEmailModal;
