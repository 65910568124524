import React, { useState, useEffect } from "react";
import { Button, Popconfirm, Spin, Table, Tag } from "antd";
import ConferenceAddQuestionModal from "./ConferenceAddQuestionModal";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestions } from "../../store/slices/fetchQuestionsSlice";
import { deleteConferenceQuestion } from "../../store/slices/deleteQuestionSlice";

const ConferenceQuestionair = () => {
  const { confId } = useParams();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const { data: questions } = useSelector((state) => state.questionslist);
  const questionslistState = useSelector((state) => state.questionslist);

  console.log(questions);

  const handleDelete = (questionId, activeStatus) => {
    dispatch(
      deleteConferenceQuestion({
        conferenceId: confId,
        questionId,
        activeStatus,
      })
    ).then(() => {
      dispatch(fetchQuestions(confId));
    });
  };

  const showModal = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    dispatch(fetchQuestions(confId));
  }, [confId]);

  const handleClose = () => {
    setModalVisible(false);
  };

  const columns = [
    {
      title: "Question Text",
      dataIndex: "text",
      key: "text",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => (
        <Tag color={type === "TEXT" ? "blue" : "green"}>{type}</Tag>
      ),
    },
    {
      title: "Options",
      key: "options",
      render: (text, record) => {
        if (record.type === "MULTIPLE_CHOICE") {
          return (
            <ul>
              {record.options.map((option) => (
                <li key={option.id}>{option.text}</li>
              ))}
            </ul>
          );
        }
        return "N/A";
      },
    },
    {
      title: "Active Status",
      dataIndex: "activeStatus",
      key: "activeStatus",
      render: (activeStatus) => (
        <Tag color={activeStatus === "ACTIVE" ? "green" : "red"}>
          {activeStatus}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        console.log("asdasd", record),
        record.activeStatus === "ACTIVE" ? (
          <Popconfirm
            title="Are you sure you want to Inactive the Status? "
            onConfirm={() => handleDelete(record.id, "DELETED")}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ backgroundColor: "red", color: "white" }}>
              Delete
            </Button>
          </Popconfirm>
        ) : (
          <Popconfirm
            title="Are you sure you want to change the Status? "
            onConfirm={() => handleDelete(record.id, "ACTIVE")}
            okText="Yes"
            cancelText="No"
          >
            <Button style={{ backgroundColor: "green", color: "white" }}>
              Restore
            </Button>
          </Popconfirm>
        )
      ),
    },
  ];

  if (questionslistState?.loading === true) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "1rem 0",
        }}
      >
        <h2>Questions Table</h2>
        <Button type="primary" onClick={showModal}>
          Add Question
        </Button>
      </div>
      <ConferenceAddQuestionModal
        visible={modalVisible}
        onClose={handleClose}
        confId={confId}
      />
      <Table dataSource={questions} columns={columns} rowKey="id" />
    </div>
  );
};

export default ConferenceQuestionair;
