import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Input, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addReviewer,
  fetchReviewers,
} from "../../store/slices/adminAddreviewerSlice";

const AddReviewersModal = ({ visible, onClose, conferenceId, setReload }) => {
  const dispatch = useDispatch();
  const reviewersState = useSelector(
    (state) =>
      state.reviewersSlice || { reviewers: [], loading: false, error: null }
  );
  const { reviewers, loading, error } = reviewersState;
  const [email, setEmail] = useState("");
  const [adding, setAdding] = useState(false);

  console.log(reviewers);

  useEffect(() => {
    if (visible) {
      dispatch(fetchReviewers(conferenceId));
    }
  }, [dispatch, visible, conferenceId]);

  const handleAddReviewer = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      notification.error({ message: "Email is required." });
      return;
    }
    if (!emailRegex.test(email)) {
      notification.error({ message: "Invalid email format." });
      return;
    }
    setAdding(true);
    dispatch(addReviewer({ conferenceId, email })).then(() => {
      setEmail("");
      setAdding(false);
      dispatch(fetchReviewers(conferenceId));
      setReload(Math.random());
    });
  };

  const columns = [
    { title: "Email", dataIndex: "email", key: "email" },
    // Add other columns if necessary
  ];

  return (
    <Modal
      title="Add Reviewers"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      {loading === true && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      )}

      {loading === false && (
        <>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Input
              placeholder="Enter reviewer email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Button
              type="primary"
              disabled={email.length === 0}
              onClick={handleAddReviewer}
              loading={adding}
            >
              Add Reviewer
            </Button>
          </div>
          <Table dataSource={reviewers} columns={columns} rowKey="email" />
        </>
      )}
    </Modal>
  );
};

export default AddReviewersModal;
