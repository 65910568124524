import { Modal, Spin, notification } from "antd";
import React, { useState } from "react";
import { Button } from "antd";
import { fileAcceptedModal } from "../../assets/enumConstants";
import { useDispatch, useSelector } from "react-redux";
import { uploadPaperSubmission } from "../../store/slices/addSubmissionDocSlice";
import { fetchpaperSubmissionDetails } from "../../store/slices/paperInfoSlice";

const UploadSubmissionDocModal = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const addSubmissionDocSlice = useSelector(
    (state) => state.addSubmissionDocSlice
  );
  const fileType = fileAcceptedModal.find(
    (file) => file?.value === props?.record?.docType
  )?.label;

  const dispatch = useDispatch();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const isValidType = file && file.type === fileType;

    if (!isValidType) {
      notification.error({
        message: "Error",
        description: `You can only upload ${props?.record?.docType} files!`,
      });

      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  const onFinish = () => {
    if (!selectedFile) {
      notification.error({
        message: "Error",
        description: "Please select a file to upload",
      });
      return;
    }

    const paperId = props.paperid;
    const submissionSettings = [props.record.id];

    dispatch(
      uploadPaperSubmission({
        paperId,
        submissionSettings,
        file: selectedFile,
      })
    ).then(() => {
      props.onClose();
      setSelectedFile(null);
      dispatch(fetchpaperSubmissionDetails(props.paperid));
    });
  };

  return (
    <Modal
      title="Upload Submission Document"
      open={props.visible}
      footer={null}
      onCancel={props.onClose}
    >
      {addSubmissionDocSlice.loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin />
        </div>
      ) : (
        <div>
          <input type="file" accept={fileType} onChange={handleFileChange} />

          <Button type="primary" onClick={() => onFinish()}>
            Submit
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default UploadSubmissionDocModal;
