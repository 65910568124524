import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Table,
  Space,
  notification,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { createConference } from "../../store/slices/createConferenceSlice";
import { useNavigate } from "react-router-dom";
import { eventTypeList } from "../../assets/enumConstants";

const { RangePicker } = DatePicker;

const CreateConference = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tracks, setTracks] = useState([]);
  const [showBasePaperId, setShowBasePaperId] = useState(false);

  const handleFormChange = (changedValues, allValues) => {
    if (allValues.paperIdGenerationType === "INCREMENTAL") {
      setShowBasePaperId(true);
    } else {
      setShowBasePaperId(false);
    }
  };

  const handleSubmitState = useSelector((state) => state.createConference);

  const handleAddTrack = () => {
    setTracks([
      ...tracks,
      { key: tracks.length, trackName: "", description: "" },
    ]);
  };

  const handleRemoveTrack = (key) => {
    setTracks(tracks.filter((track) => track.key !== key));
  };

  const handleTrackChange = (value, key, column) => {
    const newTracks = tracks.map((track) => {
      if (track.key === key) {
        return { ...track, [column]: value };
      }
      return track;
    });
    setTracks(newTracks);
  };

  const columns = [
    {
      title: "Track Name",
      dataIndex: "trackName",
      key: "trackName",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleTrackChange(e.target.value, record.key, "trackName")
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Input
          value={text}
          onChange={(e) =>
            handleTrackChange(e.target.value, record.key, "description")
          }
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button
            type="text"
            icon={<MinusCircleOutlined />}
            onClick={() => handleRemoveTrack(record.key)}
          />
        </Space>
      ),
    },
  ];

  const handleSubmit = (values) => {
    const { dates, basePaperId, ...restValues } = values;

    const formattedTracks = tracks.map((track) => ({
      name: track.trackName,
      description: track.description,
    }));

    const payload = {
      ...restValues,
      startDate: dates[0].toISOString(),
      endDate: dates[1].toISOString(),
      tracks: formattedTracks,
      basePaperId: parseInt(basePaperId, 10) ?? undefined,
    };

    console.log(payload);
    dispatch(createConference({ payload, navigate }));
  };

  if (handleSubmitState.loading === true) {
    return (
      <div className="fullPageLoading">
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="pageContainer">
      <div className="createConferencePage">
        <h2>Create Conference</h2>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          initialValues={{
            eventType: "VIRTUAL",
          }}
          onValuesChange={handleFormChange}
        >
          <Form.Item
            name="title"
            label="Conference Title"
            rules={[
              { required: true, message: "Please enter the conference title" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="handle"
            label="Handle"
            rules={[{ required: true, message: "Please enter the handle" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="websiteUrl"
            label="Website URL"
            rules={[
              { required: true, message: "Please enter the website URL" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter the description" },
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item
            name="affiliationName"
            label="Affiliation Name"
            rules={[
              { required: true, message: "Please enter the affiliation name" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: "Please enter the location" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="dates"
            label="Conference Dates"
            rules={[
              { required: true, message: "Please select the conference dates" },
            ]}
          >
            <RangePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Form.Item
            name="eventType"
            label="Event Type"
            rules={[
              { required: true, message: "Please select the event type" },
            ]}
          >
            <Select>
              {eventTypeList?.map((eventType) => (
                <Select.Option value={eventType.value}>
                  {eventType.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Tracks">
            <Table
              columns={columns}
              dataSource={tracks}
              pagination={false}
              rowKey="key"
              footer={() => (
                <Button
                  type="dashed"
                  onClick={handleAddTrack}
                  icon={<PlusOutlined />}
                >
                  Add Track
                </Button>
              )}
            />
          </Form.Item>

          <Form.Item
            name="paperIdGenerationType"
            label="Paper Id Generation Type"
            rules={[
              {
                required: true,
                message: "Please select the Id Generation type",
              },
            ]}
          >
            <Select>
              <Select.Option value="INCREMENTAL">Incremental</Select.Option>
              <Select.Option value="RANDOM">Random</Select.Option>
            </Select>
          </Form.Item>

          {showBasePaperId && (
            <Form.Item
              name="basePaperId"
              label="Base Paper Id"
              rules={[
                { required: true, message: "Please enter the Base Paper Id" },
              ]}
            >
              <Input type="number" />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CreateConference;
